<template>
  <v-container fluid v-resize="() => resizeTable('organizations')">
    <v-card>
      <v-card-title class="pb-0">
        {{ $t('Organizations') }}
        <v-spacer />

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              @click="
                ($event) => {
                  isSearchPanelOpen = !isSearchPanelOpen;
                  resizeTable('organizations');
                  $event.target.blur();
                }
              "
              >{{ isSearchPanelOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon
            >
          </template>
          <div class="filter-tooltip">
            {{ isSearchPanelOpen ? $t('Hide filter') : $t('Show filter') }}
          </div>
        </v-tooltip>
      </v-card-title>
      <v-flex>
        <v-card-text>
          <div id="searchPanel" v-show="isSearchPanelOpen">
            <v-row>
              <v-col cols="4" md="4" xl="2"
                ><gli-text-field
                  clearable
                  v-model="filter.name"
                  :label="$t('Name')"
                  single-line
                ></gli-text-field
              ></v-col>
            </v-row>
          </div>

          <v-data-table
            id="organizations"
            :mobile-breakpoint="0"
            :headers="headers.map((item) => ({ ...item, text: $t(item.text) }))"
            fixed-header
            :items="filteredOrganizations"
            item-key="id"
            :loading="isLoading"
            :options.sync="pagination"
          >
            <template v-slot:item.name="{ value }">
              <span>{{ value }}</span>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <gli-editor-button
                    class="ml-2"
                    color="primary"
                    fab
                    x-small
                    dark
                    v-on="on"
                    @click="edit(item.id)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </gli-editor-button>
                </template>
                <span>{{ $t('Edit') }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <gli-editor-button
                    class="ml-2"
                    color="error"
                    fab
                    x-small
                    dark
                    v-on="on"
                    @click="deleteOrganization(item.id)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </gli-editor-button>
                </template>
                <span>{{ $t('Delete') }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-flex>
      <v-card-actions class="mr-2">
        <v-spacer></v-spacer>

        <gli-editor-button color="primary" @click="addNew">{{
          $t('New organization')
        }}</gli-editor-button>
      </v-card-actions>
    </v-card>
    <organization-dialog v-if="editedOrganization && pageLoaded"></organization-dialog>
  </v-container>
</template>

<style lang="scss" scoped>
::v-deep .row-error {
  color: var(--v-error-base);
}

.select-filter.v-icon:focus::after {
  opacity: 0 !important;
}

#searchPanel {
  padding: 10px;
  ::v-deep .v-label {
    font-size: 0.9em;
  }
  .col-4,
  .col-12 {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.v-application .v-data-table .v-data-table__expanded .v-data-table.table-details {
  background: var(--v-background-darken1);
  padding: 6px;
  border-radius: 0;
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex';
import resizeTable from '../mixins/resizeTable';
import OrganizationDialog from '../components/OrganizationDialog.vue';

const emptyFilter = {
  name: ''
};

export default {
  name: 'Organizations',

  data() {
    return {
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: '',
          value: 'actions',
          width: 125,
          sortable: false,
          align: 'end'
        }
      ],
      isSearchPanelOpen: true,
      filter: JSON.parse(JSON.stringify(emptyFilter)),

      pagination: {
        page: 1
      }
    };
  },

  async mounted() {
    await this.getAll();
    this.setOrganizationPageLoaded(true);
  },

  async beforeDestroy() {
    this.checkChanges = false;
    this.setOrganizationPageLoaded(false);
  },

  computed: {
    ...mapGetters('organization', [
      'organizations',
      'isLoading',
      'editedOrganization',
      'pageLoaded'
    ]),
    filteredOrganizations() {
      let filtered = this.organizations;

      for (const [key, value] of Object.entries(this.filter)) {
        if (!value) {
          continue;
        }

        // v-select filter
        if (Array.isArray(value)) {
          if (value.length) {
            filtered = filtered.filter((freight) => value.includes(freight[key]));
          }
        } else {
          // gli-text-field filter
          const regexp = new RegExp(value, 'i');
          filtered = filtered.filter((item) => item[key].match(regexp));
        }
      }

      return filtered;
    }
  },

  watch: {
    filter: {
      handler() {
        this.pagination.page = 1;
      },
      deep: true
    }
  },

  methods: {
    ...mapActions('alert', ['error']),
    ...mapActions('organization', ['getAll', 'addNew', 'edit']),
    ...mapActions('organization', {
      deleteOrganization: 'delete',
      setOrganizationPageLoaded: 'setPageLoaded'
    })
  },

  mixins: [resizeTable],

  components: {
    OrganizationDialog
  }
};
</script>
