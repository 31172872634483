var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{directives:[{name:"resize",rawName:"v-resize",value:(function () { return _vm.resizeTable('organizations'); }),expression:"() => resizeTable('organizations')"}],attrs:{"fluid":""}},[_c('v-card',[_c('v-card-title',{staticClass:"pb-0"},[_vm._v(" "+_vm._s(_vm.$t('Organizations'))+" "),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({on:{"click":function ($event) {
                _vm.isSearchPanelOpen = !_vm.isSearchPanelOpen;
                _vm.resizeTable('organizations');
                $event.target.blur();
              }}},on),[_vm._v(_vm._s(_vm.isSearchPanelOpen ? 'mdi-chevron-up' : 'mdi-chevron-down'))])]}}])},[_c('div',{staticClass:"filter-tooltip"},[_vm._v(" "+_vm._s(_vm.isSearchPanelOpen ? _vm.$t('Hide filter') : _vm.$t('Show filter'))+" ")])])],1),_c('v-flex',[_c('v-card-text',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSearchPanelOpen),expression:"isSearchPanelOpen"}],attrs:{"id":"searchPanel"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4","md":"4","xl":"2"}},[_c('gli-text-field',{attrs:{"clearable":"","label":_vm.$t('Name'),"single-line":""},model:{value:(_vm.filter.name),callback:function ($$v) {_vm.$set(_vm.filter, "name", $$v)},expression:"filter.name"}})],1)],1)],1),_c('v-data-table',{attrs:{"id":"organizations","mobile-breakpoint":0,"headers":_vm.headers.map(function (item) { return (Object.assign({}, item, {text: _vm.$t(item.text)})); }),"fixed-header":"","items":_vm.filteredOrganizations,"item-key":"id","loading":_vm.isLoading,"options":_vm.pagination},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
              var value = ref.value;
return [_c('span',[_vm._v(_vm._s(value))])]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('gli-editor-button',_vm._g({staticClass:"ml-2",attrs:{"color":"primary","fab":"","x-small":"","dark":""},on:{"click":function($event){return _vm.edit(item.id)}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Edit')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('gli-editor-button',_vm._g({staticClass:"ml-2",attrs:{"color":"error","fab":"","x-small":"","dark":""},on:{"click":function($event){return _vm.deleteOrganization(item.id)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Delete')))])])]}}])})],1)],1),_c('v-card-actions',{staticClass:"mr-2"},[_c('v-spacer'),_c('gli-editor-button',{attrs:{"color":"primary"},on:{"click":_vm.addNew}},[_vm._v(_vm._s(_vm.$t('New organization')))])],1)],1),(_vm.editedOrganization && _vm.pageLoaded)?_c('organization-dialog'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }